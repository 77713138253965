.WorldMapPage
{
  display: flex;
  flex-direction: column;
}

/* Media query for portait mode on small devices */
@media (max-width: 600px) {
  .WorldMapPage .MapContainer {
    position: relative;
    width: 100vw !important; /* Full viewport width */
    border: 0px !important;
  }

  .Counters{
    display: none !important;
  }

  .rsm-svg{
    height: 80vh;
    width: 100%;
  }
  
}

/* Make the map container an oval */
.WorldMapPage .MapContainer {
  background-color: #2C2C2C;
  border: 2px solid #ddd;
  width: 100%;
  height: 100%;
  border-radius: 1%;
  align-items: center;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.ComposableMap {
  width: 100%;
  /* Ensure the map fills the container width */
  height: 100%;
  /* Ensure the map fills the container height */
  object-fit: contain;
  /* Make sure the map scales without distortion */
}

.SearchBarResults
{
  overflow-y: auto;
  max-height: 300px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  position: absolute;
  background: white;
}

.SearchBarResultsList
{
    list-style:none;
    padding: 0;
    margin: 0;
    display: block;
}

.SearchBarResultsItem
{
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    color: black;
    display: block;
}

.SearchBarContainer
{
  min-height: 10;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  left: 50%;
  z-index: 1000;
}

.SearchBarInput
{
  padding: 10px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.Counters{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  max-height: 100%;
}

.SightseeingCounter
{
  text-align: center;
  color: #333;
  height: 160px;
  width: 100px;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.SightseeingChart {
  width: 100%;
  height: 100%;
  position: relative;
}

.FoodCounter
{
  text-align: center;
  color: #333;
  height: 160px;
  width: 100px;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.FoodChart {
  width: 100%;
  height: 100%;
  position: relative;
}

.ChartTitle
{
  font-size: 70%;
}

.InnerCircleNumber
{
  font-size: 80%;
}

.recharts-legend-item
{
  width: 30%;
  font-size: 50%;
}

.recharts-legend-item .recharts-surface
{
  height: 10%;
  width: 20%;
}


.MainOverlay {
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-top: 40px;
  border-radius: 8px;
  width: 300px;
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Ensure relative positioning for alignment */
}

.ModalOverlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.OverlayInfo{
  margin: 10px;
}

.OverlayCloseButton{
  top: 0 !important;
  right: 0 !important;
  padding: 0px;
  font-size: 16px;
  background-color: #f4f4f4;
  border: none;
  color: #4CAF50;
  border: none;;
  border-radius: 5px;
  cursor: pointer;
}

.OverlayTitle
{
  padding: 10px;
  background-color: #4c4c4c;
  color: #ffffff;
  margin-bottom: 10px; /* Add spacing between list items */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.OverlaySightseeingTopic {
  list-style-type: none; /* Removes bullet points */
  background: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  position: relative; /* Use absolute positioning to place it outside */
  width: 100px; /* Match the width of MainOverlay for visual consistency */
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

li.ButtonTopicSightseeing {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #2b6589;
  height: 100px;
  color: #ffffff;
  margin-bottom: 10px; /* Add spacing between list items */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ButtonTopicSightseeing:hover {
  background-color: #2b6589;
  transform: scale(1.05);
}

.ButtonTopicSightseeing:active {
  background-color: #22557f;
}

.OverlayFoodTopic {
  list-style-type: none; /* Removes bullet points */
  background: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  width: 100px; /* Match the width of MainOverlay for visual consistency */
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

li.ButtonTopicFood {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #2b6589;
  color: #ffffff;
  height: 100px;
  margin-bottom: 10px; /* Add spacing between list items */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ButtonTopicFood:hover {
  background-color: #2b6589;
  transform: scale(1.05);
}

.ButtonTopicFood:active {
  background-color: #22557f;
}

.OverlayStatisticsTopic {
  list-style-type: none; /* Removes bullet points */
  background: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  width: 100px; /* Match the width of MainOverlay for visual consistency */
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

li.ButtonTopicStatistics {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #2b6589;
  color: #ffffff;
  height: 100px;
  margin-bottom: 10px; /* Add spacing between list items */
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.ButtonTopicStatistics:hover {
  background-color: #2b6589;
  transform: scale(1.05);
}

.ButtonTopicStatistics:active {
  background-color: #22557f;
}


button:hover {
  background-color: #45a049;
}

button:active {
  background-color: #3e8e41;
}

.material-icons.icon {
  font-size: 100%;
  position: relative;
  color: #4CAF50; /* Icon color */
}