.HomePage {
  height: 100%; /* Full viewport height */
  width: 100%;
  background-color: #2C2C2C;
  display: flex;
  flex-direction: column;
}


.HomePage .Divider{
  width: auto;
  padding: 10px;
  margin: 10px;
  flex-direction: row;
  position: relative; /* Make sure the element is positioned for pseudo-elements */
}

.HomePage .Divider::after {
  content: ''; /* Empty content */
  position: absolute; /* Position it absolutely within the parent */
  bottom: 50%; /* Place it at the bottom */
  left: 50%; /* Align it to the center */
  transform: translateX(-50%); /* Adjust by half its width to center it */
  width: 70%; /* Change this value to adjust the length of the border */
  border-bottom: 2px solid #ddd; /* Your desired border style */
}
/* very usefull
container-type: inline-size; /* Set as a container for queries 
@container (max-width: 30vh) {
  .GlobeWithButton {
    display: none;
  }
}
*/

.HomePage .Title{
  width: auto;
  text-align: center;
  text-align: center;
  color: #f8f8f8; /* Light text color for better contrast */
  background-color: #22557f;
  padding: 10px;
  margin: 10px;
  font-size: 14px;
  flex-direction: row;
  position: relative; /* Make sure the element is positioned for pseudo-elements */
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.HomePage .Title::after {
  content: ''; /* Empty content */
  position: absolute; /* Position it absolutely within the parent */
  bottom: 0; /* Place it at the bottom */
  left: 50%; /* Align it to the center */
  transform: translateX(-50%); /* Adjust by half its width to center it */
  width: 70%; /* Change this value to adjust the length of the border */
  border-bottom: 2px solid #ddd; /* Your desired border style */
}

.HomePage .Paragraph1
{
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.HomePage .ExplanationBlock {
  position: relative;
  border: 2px solid #ddd;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  color: #333;
  justify-self: center; /* Centers horizontally */
  margin-bottom: 10px;
  margin-top: 10px;
}

.HomePage .ExplanationBlock .ExplanationTitleBanner{
  position: relative;
  background-color: #4c4c4c;
  width: auto;
  height: auto;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.HomePage .ExplanationBlock::before {
  border-radius: 10px;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0) 200%);
  z-index: 2; /* Ensures the gradient is on top of the image but below the text */
  pointer-events: none; /* Allows interaction with underlying content */
}

.HomePage .ExplanationBlock .ExplanationContent::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0) 200%);
  z-index: -1; /* Ensures the gradient is on top of the image but below the text */
  pointer-events: none; /* Allows interaction with underlying content */
  border-radius: 10px;
  margin: 0;
}
.HomePage .ExplanationBlock .ExplanationContent {
  border-radius: 10px;
  position: relative;
  margin: 10px;
  z-index: 3; /* Ensures this text container is above gradients and background */
  color: #333; /* Ensure text is visible against the background */
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px; /* Add a font size for better readability */
}


.HomePage .LocationLabel {
  display: flex;
  flex-direction: row;
  position: absolute;
  height: auto;
  width: auto;
  padding: 2px;
  background-color: #2C2C2C;
  top: 4px;
  right: 4px;
  border-radius: 10px;
  object-fit: cover;
  z-index: 50; /* Keeps the image behind */
}

.HomePage .Locationtext {
  display: flex;
  position: relative;
  height: auto;
  width: auto;
  padding: 2px;
  background-color: #2C2C2C;
  color: #f8f8f8;
  top: 4px;
  right: 4px;
  border-radius: 10px;
  object-fit: cover;
  z-index: 50; /* Keeps the image behind */
}

.HomePage .material-icons.icon-region {
  display: flex;
  font-size: auto;
  position: relative;
  height: auto;
  width: auto;
  color: #4CAF50;
  margin:auto;
  display: flex;
  align-items: center;
}

.HomePage .ImageBehindText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  z-index: 1; /* Keeps the image behind */
}

.slider-container {
  justify-self: center; /* Centers horizontally */
  display: flex;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-top: 10px;
}

.slider {
  display: flex;
  width: 100%;
  animation: slide 20s infinite;
}

.slide {
  position: relative;
  flex: 1 0 100%;
  height: auto;
  border-radius: 10px;
}

.slide img {
  margin: 2px;
  width: 296px; /* image width plus twice the margin */
  height: 296px;
  object-fit: cover;
  border-radius: 10px;
}

@keyframes slide {
  0%, 20% { transform: translateX(0); }
  20%, 40% { transform: translateX(0); }
  40%, 60% { transform: translateX(-100%); }
  60%, 80% { transform: translateX(-100%); } 
  80%, 100% { transform: translateX(-200%); } 
}


.HomePage .Paragraph2
{
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.HomePage .WebsiteDescription{
  height: 300px;
  width: 300px;
  color: #f8f8f8; /* Light text color for better contrast */
  background-color: #22557f;
  font-size: 10px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 10px;
  position: relative;
  justify-self: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.HomePage .WebsiteDescription li {
  padding: 10px;
  display: flex;
  align-items: center;
  margin: auto;
  font-size: 12px;
}

.HomePage .WebsiteDescriptionList {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.HomePage .GlobeWithButton{ 
  margin: auto;
  width: 50%;
  height: 100%;
  display: flex;
  position: relative; /* Set position relative to the container */
  border-radius: 10px;
}

.HomePage .GlobeAnimation{
  margin: 0;
  width: 100%;
  height: auto;
  background-color: #2b6589;
  border-radius: 10px;
}

.HomePage .GlobeButton{
  position: absolute; /* Absolute positioning to overlap the globe */
  top: 50%; /* Center the button vertically */
  left: 50%; /* Center the button horizontally */
  transform: translate(-50%, -50%); /* Adjust the button to be perfectly centered */
  background-color: #646464;
  padding: 3px;
  border: none;
  color: white;
  border-radius: 5px;
  border: 2px solid #ddd;
  font-size: 20px;
  cursor: pointer;
}

.HomePage .GlobeButton:hover {
  background-color: rgb(200, 200, 200); /* Change color on hover */
}

.HomePage .TravelTimeCalculator
{
  display:flex;
  width: auto;
  height: auto;
  flex-direction: column;
  border-radius: 5px;
}

.HomePage .ItineraryBuilder
{
  width: auto;
  height: auto;
  display:flex;
  flex-direction: column;
  border-radius: 5px;
}

.HomePage .Paragraph3
{
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  width: auto;
  height: 100%;;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
}


.FeedbackList{
  background-color: rgba(255, 255, 255, 0.329);
}

/* Style the scrollbar itself */
::-webkit-scrollbar {
  width: 15px;  /* Width of vertical scrollbar */
  height: 15px; /* Height of horizontal scrollbar */
}

/* Style the track (the area the thumb moves within) */
::-webkit-scrollbar-track {
  background-color: #707070;  /* Light gray track */
  border-radius: 10px;         /* Rounded corners */
}

/* Style the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;      /* Dark gray thumb */
  border-radius: 10px;         /* Rounded corners */
}

/* Style the thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #afafaf;      /* Darker thumb on hover */
}
