
.StatisticsPage .StatisticsGrid
{
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.StatisticsPage .StatisticsTable
{
}

.StatisticsPage .TextContainer
{
  margin: auto;
  background-color:#2b6589;
  border: 2px solid #646464;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}

.TextContainer h2 {
  text-align: center;
  width: 100%; /* Ensures it takes full width */
}

.StatisticsPage .CurrencyBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 2px solid #ddd;
    background-color: #f4f4f4;
    width: 300px;
    height: auto;
    border-radius: 10px;
    color: #333;
    justify-self: center; /* Centers horizontally */
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .StatisticsPage .GdpBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 2px solid #ddd;
    background-color: #f4f4f4;
    width: 300px;
    height: auto;
    border-radius: 10px;
    color: #333;
    justify-self: center; /* Centers horizontally */
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .StatisticsPage .PopulationBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 2px solid #ddd;
    background-color: #f4f4f4;
    width: 300px;
    height: auto;
    border-radius: 10px;
    color: #333;
    justify-self: center; /* Centers horizontally */
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .StatisticsPage .CountryBlock {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 2px solid #ddd;
    background-color: #f4f4f4;
    width: 300px;
    height: auto;
    border-radius: 10px;
    color: #333;
    justify-self: center; /* Centers horizontally */
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .StatisticsPage .Flag {
    position: relative;
    margin: 10px;
    width: 120px;
    height: auto;
  }

  .StatisticsPage .CoinAnimation {
    position: relative;
    margin: 10px;
    width: 120px;
    height: auto;
  }

  .StatisticsPage .material-icons{
    color: #4CAF50;
  }