.App {
  color: white;
  font-family: Arial, sans-serif;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content-container {
  height: 100%;
  width: 85vw;
}

.page {
  display: flex;
  flex-direction: row;
  height: 100%; /* Full viewport height */
  width: 100%; /* Full viewport width */
}

.side-bar {
  height: 95vh;
  display: flex;
  flex-direction: column;
  min-width: 15vw;
}

.mobile-menu {
  display: none;
}

/* Hide Sidebar and Show Bottom Menu on Small Screens */
@media (max-width: 600px) {
  .side-bar {
    display: none;
  }

  .Bar .WebsiteTitle {
    font-size: 16px !important; /* Font size is 5% of the viewport width */
    padding-top: 16px !important;
  }

  .content-container {
    padding-bottom: 8vh;
    width: 100vw;
  }

  .mobile-menu {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 8vh;
    width: 100vw;
    background-color: #22557f;
    border-top: 2px solid #ddd;
    justify-content: space-around;
    padding: 5px 0;
    z-index: 1000;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .mobile-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 5px;
    text-align: center;
    font-size: 0.9em;
    color: #f4f4f4;
    text-decoration: none;
  }

  .mobile-menu-item span.material-icons {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .mobile-menu-item.active {
    color: #4CAF50;
    border-radius: 5px;
    background-color: #f4f4f4;
  }

  .mobile-menu-item:hover {
    color: #4CAF50;
    border-radius: 5px;
    background-color: #f4f4f4;
  }
}

.Bar {
  background-color: #22557f;
  width: 100vw;
  margin: 0;
  top: 0;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
}

.Bar .WebsiteTitle {
  font-size: 24px; /* Font size is 5% of the viewport width */
  padding: 10px;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  /* Add lighter text shadow for depth */
  text-shadow: 
    1px 1px 2px rgba(0, 0, 0, 0.3), /* Slight outer shadow */
    2px 2px 3px rgba(0, 0, 0, 0.2), /* Deeper but softer shadow */
    0 0 5px rgba(255, 255, 255, 0.2); /* Subtle glow effect */
  
  /* Optional: Add 3D perspective */
  transform: perspective(500px) rotateX(5deg);
  color: #f8f8f8; /* Light text color for better contrast */
}

.Bar .Logo
{
  flex-direction: row;
  border-radius: 50%;
  height: 45px;
  width: 45px;/*45*/
  margin: 10px;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
}

.GlobeAnimation{
  height: 100%;
  width: 100%;
  display: flex;
  position: relative; /* Set position relative to the container */
}

.side-bar .navigation {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

/* Media query for portait mode on small devices */
@media (max-width: 600px) {
  .button-link
  {
    display: none !important; /* Hide the button-link on smaller screens */
  }
}

.side-bar .Menu {
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  width: 100%;
  height: 25%;
  margin: 0;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: relative; /* Necessary for positioning the chevron */
}

.side-bar .Menu-List {
  list-style-type: none;
  left: 0;
  margin: 0;            /* Remove any default margin */
  padding: 0;           /* Remove any padding */
  width: 100%;          /* Ensure it takes the full width */
}

.side-bar .Menu-Item {
  margin: 0;            /* Remove margin between list items */
  width: 100%;          /* Ensure each list item takes the full width */
  height: 25%;
  display: flex;
  flex-direction: row;
}

.side-bar {
  left: 0;
}

.button-link {
  display: flex; /* Enables flexbox on the element */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  background-color: #555;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  height: 80%;
  width: 50%;
  font-size: 1vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  padding: 0; /* Remove any padding */
  text-align: center; /* Ensure text alignment */
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5%;
}

.button-link:hover {
  background-color: #666;
  transform: scale(1.05);
}

.button-link:active {
  background-color: #444;
}

.PolicyButton
{
position: relative;
bottom:0;
}

.PolicyButtonLink {
  display: flex; /* Enables flexbox on the element */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  background-color: #2b6589;
  color: white;
  border-radius: 5px;
  height: 30px;;
  width: 100%;
  font-size: 1vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center; /* Ensure text alignment */
  margin: auto;
}

.PolicyButtonLink:hover{
  background-color: #22557f;
  transform: scale(1.05);
}

.PolicyButtonLink:active{
  background-color: #2b6589;
  transform: scale(1.05);
}

.side-bar .material-icons.icon-side-bar {
  font-size: auto;
  position: absolute;
  height: 25%;
  color: #4CAF50;
  margin:auto;
  display: flex;
  align-items: center;
}

.material-icons.icon-side-bar:hover {
  color: #666;
  transform: scale(1.05);
}

.material-icons.icon-side-bar:active {
  color: #444;
}

.side-bar .material-icons.chevron {
  display: flex;
  font-size: auto;
  position: absolute;
  right: 0;
  top:0;
  height: 25%;
  color:#4CAF50;
  transition: top 0.3s ease;
  transform: translateY(100%); /* This gives the initial offset of 10% from the top */
  align-items: center; /* Ensure text alignment */
}

h1 {
  color: white;
  text-align: center;
  font-size: 24px;
  margin: 0px;
}
h2 {
  color: white;
  text-align: center;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;  /* Inherit color from parent */
  font-weight: normal;
}