.LatestPage{
    height: auto;
}

.TimeStamp{
    color: black;
}

.LatestPage .array-item
{
    margin: 0;
    height: auto;
    width: auto;
    margin: 5px;
}

.LatestPostsContainer {
    justify-self: center;
    margin-left: 20px;
    margin-right: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 335px;
    height: 425px;
    position: relative;
}

.LatestPostsArray {
    background-color: #f4f4f4;
    border: 2px solid #ddd;
    border-radius: 10px;
    width: 335px;
    height: 425px;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease;
}

.LatestPostsArray.hidden {
    opacity: 0;
    pointer-events: none;
}

.LatestPostsArray.visible {
    opacity: 1;
    pointer-events: all;
}

/**** New Flip Tab Styles ****/
.FlipTab {
    position: relative;
    display: flex;
    justify-self: center;
    background-color: white;
    padding: 5px 10px;
    border: 2px solid #ddd;
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    z-index: 1;
}

.FlipTab .tab {
    background: transparent;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    color: #555;
    font-weight: bold;
    transition: color 0.3s;
}

.FlipTab .tab.active {
    color: #4CAF50;
    border-bottom: 2px solid #4CAF50;
}
