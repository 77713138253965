/* Ensure the entire page is used by the grid */
.page {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    align-items: flex-start;
  }
  
  
  /* Background container for the grid */
  .grid-background {
    width: 100%; /* Full width of the page */
    height: 100%; /* Full viewport height */
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0; /* Light background color */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    border-radius: 10px;
  }
  
  /* Grid container inside the background */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Ensures blocks are at least 300px */
    gap: 20px; /* Space between grid items */
    width: 100%; /* Full width of the grid container */
    max-width: 100%; /* Prevent grid from expanding outside its container */
    height: 100%;
    box-sizing: border-box;
  }
  
  /* Grid item styling */
  .grid-item {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  
  .button-content {
    width: 100%;
    height: 100%;
    display: list-item;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
    overflow: hidden;
  }
  
  .button-content:hover {
    background-color: #e0e0e0; /* Slight background color change on hover */
  }
  
  .material-icons.icon {
    font-size: 36px;
    color: #4CAF50; /* Icon color */
    margin-bottom: 10px; /* Space below the icon */
    flex-shrink: 0; /* Prevent shrinking */
  }

  .picture-content 
  {
    aspect-ratio: 1 / 1; /* Makes the container square */
    position: top;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    overflow:hidden;
  }

  .picture-content img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without distortion */
  }
  
  .block-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .block-text {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }

  /* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content */
.popup-content {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  height: 500px;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}


.popup-overlay .popup-header{
  display: flex;
  justify-content: center; /* Space out the elements */
  flex-direction: row;
}

.OverlayCloseButton{
  width: 40px;
  height: 40px;
  background-color: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto; /* Pushes it to the right */
}

.OverlayTitle{
  flex-grow: 1;  /* Allow it to take available space */
  text-align: center; /* Center the text */
  font-size: 24px; /* Adjust size if needed */
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center; /* Ensures the text and icon are centered */
}

.ComposableMap {
  width: 100%;
  /* Ensure the map fills the container width */
  height: 100%;
  /* Ensure the map fills the container height */
  object-fit: contain;
  /* Make sure the map scales without distortion */
}

.popup-content .rsm-svg{
  height: 65%;
  width: 100%;
}

.material-icons.icon {
  font-size: 24px;
  position: relative;
  color: #4CAF50; /* Icon color */
}
  