.SightseeingPage .array-container{
    width:90%;
    height:300px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f4f4f4;
    border: 2px solid #ddd;
    display:flex;
    flex-direction: row;
    overflow-x: auto; /* Allow horizontal scrolling */
    overflow-y: hidden; /* Prevent vertical scrolling */
    border-radius: 5px;
    padding : 5px;
    cursor: grab;
    scrollbar-width: thin; /* Thin scrollbar for modern browsers */
}

.array-container.active {
  cursor: grabbing; /* Grabbing cursor */
  background-color: #eaeaea; /* Optional: Slight background change */
  transition: background-color 0.2s ease; /* Smooth transition effect */
}

.array-container::-webkit-scrollbar {
  height: 8px; /* Horizontal scrollbar height */
}

.array-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.SightseeingPage .array-container .picture-content 
{
  aspect-ratio: 1 / 1; /* Makes the container square */
  width: 70%;
  height: 50%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  overflow:hidden;
  border-radius: 5px;
}

.SightseeingPage .array-item {
    min-width: 100px; /* Minimum width for items */
    max-width: 250px; /* Minimum width for items */
    flex: 0 0 auto; /* Prevent stretching and ensure each item takes minimum width */
    height: 100%; /* Fill the height of the container */
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #f4f4f4;
    border: 2px solid #ddd;
    border-radius: 5px;
    text-overflow: ellipsis; /* Add ellipsis for overflowing text (optional) */
    overflow: hidden; /* Hide overflow if ellipsis is used */
  }

  .quick-select{
    display: flex;
    flex-direction: column;
  }

  .SightseeingPage .block-title {
    padding: 5px 10px;
    white-space: nowrap;        /* Keep text on one line */
    overflow: hidden;           /* Hide overflow */
    text-overflow: ellipsis;    /* Add '...' if the text is too long */
    display: block;
    width: 100%;                /* Ensure it takes up the full width */
    transition: font-size 0.3s; /* Smooth transition for resizing */
  }

  .description-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    transition: background-color 0.3s;
  }

  .SightseeingPage .coordinates{
    background-color: white;
    color: black;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 10px;
    width: auto;
    font-size: 10px;
  }
  
  .description-button:hover {
    background-color: #59d35d;
  }


/* Highlighted (clicked) post item */
.array-item.active {
  border-color: #478fbc; /* Blue border */
  box-shadow: 0 0 10px #478fbc; /* Blue glow */
}

.SightseeingPage .PostWiki
{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
}
.SightseeingPage .LeafletContainer
{
  width: 300px;
  position: relative;
  margin: auto;
  padding: 2px;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.SightseeingPage .Description
{
  justify-self: center; /* Centers horizontally */
  color: #333;
  width: 300px;
  height: auto;
  position: relative;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.SightseeingPage .Description .DescriptionTitleBanner
{
  justify-items: center;
  position: relative;
  background-color: #4c4c4c;
  color: #f8f8f8;
  width: auto;
  height: auto;
  border-radius: 10px;
  margin: 5px;
}

.SightseeingPage .Description .DescriptionText
{
  justify-items: center;
  position: relative;
  margin: 5px;
}

.SightseeingPage .ExtraPictures
{
  justify-self: center; /* Centers horizontally */
  width: 300px;
  position: relative;
  background-color: #f4f4f4;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 20px;
  right: 20px;
  background: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: auto;
  font-weight: bold;
}

/* Marker container */
.custom-marker {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #4CAF50; /* Cool blue color */
  border: 3px solid #fff; /* White border for a clean look */
  border-radius: 50%; /* Make it circular */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  cursor: pointer;
  transform: translate(-50%, -100%); /* Align pointer to the coordinates */
}

/* Pointer triangle at the bottom */
.custom-marker::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #22557f; /* Same color as the marker */
}

/* Hover effect for the marker */
.custom-marker:hover {
  background-color: #22557f; /* Darker blue on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* More shadow on hover */
  transform: translate(-50%, -100%) scale(1.1); /* Slight zoom effect */
}

/* Active state style for the clicked marker */
.custom-marker.active {
  background-color: #22557f; /* Darker blue when active */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* More shadow when active */
}